import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";
import { FaMusic, FaCode } from "react-icons/fa";
import css from "@emotion/css";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      css={css`
        text-align: center;
        margin-top: 64px;
        margin-bottom: 32px;
      `}
    >
      <h1>Hi there! What brings you here?</h1>

      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: ${32 + 16}px;
          margin-bottom: 64px;
          a {
            color: rgba(0, 0, 0, 0.8);
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
          }
        `}
      >
        <Link
          to={"/music/"}
          css={css`
            display: flex;
            align-items: center;
            margin-right: 64px;
          `}
        >
          <FaMusic size={16 + 8} />
          <div
            css={css`
              margin-left: 16px;
              font-size: 150%;
            `}
          >
            Music
          </div>
        </Link>

        <Link
          to={"/portfolio/"}
          css={css`
            display: flex;
            align-items: center;
            margin-right: 32px;
          `}
        >
          <FaCode size={16 + 8} />
          <div
            css={css`
              margin-left: 16px;
              font-size: 150%;
            `}
          >
            Web Development
          </div>
        </Link>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
